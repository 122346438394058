import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { HeroLayout } from '@primer/gatsby-theme-doctocat';
import { Text, Link, Box, Heading } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = HeroLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Box pb={4} display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={4} mdxType="Box">
  <Box mdxType="Box">
    <Heading as="h3" sx={{
          color: 'fg.default'
        }} mdxType="Heading">
      <Link sx={{
            fontSize: 4,
            color: 'fg.default'
          }} as={GatsbyLink} to="/guides/" mdxType="Link">
        Guides
      </Link>
    </Heading>
    <Text as="p" sx={{
          fontSize: 2,
          color: 'fg.muted'
        }} mdxType="Text">
      Standards, guidelines, and tools to getting started with Primer.
    </Text>
  </Box>
  <Box mdxType="Box">
    <Heading as="h3" sx={{
          color: 'fg.default'
        }} mdxType="Heading">
      <Link sx={{
            fontSize: 4,
            color: 'fg.default'
          }} as={GatsbyLink} to="/foundations/" mdxType="Link">
        Foundations
      </Link>
    </Heading>
    <Text as="p" sx={{
          fontSize: 2,
          color: 'fg.muted'
        }} mdxType="Text">
      The fundamental parts of the design system that underpin all GitHub interfaces, such as color and typography.
    </Text>
  </Box>
  <Box mdxType="Box">
    <Heading as="h3" sx={{
          color: 'fg.default'
        }} mdxType="Heading">
      <Link sx={{
            fontSize: 4,
            color: 'fg.default'
          }} as={GatsbyLink} to="/ui-patterns/" mdxType="Link">
        UI patterns
      </Link>
    </Heading>
    <Text as="p" sx={{
          fontSize: 2,
          color: 'fg.muted'
        }} mdxType="Text">
      Design guidelines covering common user workflows.
    </Text>
  </Box>
  <Box mdxType="Box">
    <Heading as="h3" sx={{
          color: 'fg.default'
        }} mdxType="Heading">
      <Link sx={{
            fontSize: 4,
            color: 'fg.default'
          }} as={GatsbyLink} to="/components/" mdxType="Link">
        Components
      </Link>
    </Heading>
    <Text as="p" sx={{
          fontSize: 2,
          color: 'fg.muted'
        }} mdxType="Text">
      Design and usage guidelines for individual Primer components.
    </Text>
  </Box>
  <Box mdxType="Box">
    <Heading as="h3" sx={{
          color: 'fg.default'
        }} mdxType="Heading">
      <Link sx={{
            fontSize: 4,
            color: 'fg.default'
          }} as={GatsbyLink} to="/github-staff/" mdxType="Link">
        GitHub staff
      </Link>
    </Heading>
    <Text as="p" sx={{
          fontSize: 2,
          color: 'fg.muted'
        }} mdxType="Text">
      Internal documentation for GitHub staff.
    </Text>
  </Box>
  <Box mdxType="Box">
    <Heading as="h3" sx={{
          color: 'fg.default'
        }} mdxType="Heading">
      <Link sx={{
            fontSize: 4,
            color: 'fg.default'
          }} as={GatsbyLink} to="/native/" mdxType="Link">
        Native
      </Link>
    </Heading>
    <Text as="p" sx={{
          fontSize: 2,
          color: 'fg.muted'
        }} mdxType="Text">
      Principles, foundations and usage guidelines for designing for GitHub's native products.
    </Text>
  </Box>
    </Box>
    <hr />{' '}
    <Box pt={3} mdxType="Box">
  <Heading as="h4" sx={{
        fontSize: 5
      }} mdxType="Heading">
    Open source
  </Heading>
  <Text as="p" sx={{
        fontSize: 2,
        mt: 3
      }} mdxType="Text">
    Primer is open-sourced on GitHub.{' '}
    <Link as={GatsbyLink} to="https://github.com/primer" mdxType="Link">
      Contributions and feedback
    </Link>{' '}
    are welcome!
  </Text>
  <Text as="p" sx={{
        fontSize: 2
      }} mdxType="Text">
    <Text sx={{
          fontWeight: 'bold'
        }} mdxType="Text">Need help?</Text> If you found a bug on this website, please{' '}
    <Link as={GatsbyLink} to="https://github.com/primer/design/issues/new" mdxType="Link">
      open a new issue
    </Link>{' '}
    with as much detail as possible.
  </Text>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      